<template>
  <div class="mHeader">
    <nav class="nav-bar">
      <van-popup
        v-model="showMenu"
        closeable
        position="right"
        overlay-class="header-overlay"
      >
        <div class="link-list">
          <ul>
            <li v-for="(item, index) in tabs" @click="toPage(item.link)">
              <div class="link-name">{{ item.name }}</div>
              <div class="line" />
              <van-icon name="arrow" />
            </li>
          </ul>
        </div>

        <div class="logo-part">
          <img
            src="http://video.meshmellow.cn/cloud/uploads/video/sys/20191210/Nsyb7N5GBxsaBizt7YXT6md5Bt47NRBj.png"
            @click="toPage('VUE_APP_MAINWEBSITE')"
            alt=""
          />
          <div class="last-line"></div>
          <div class="icons-part">
            <img
              src="https://meshmellow.oss-cn-hangzhou.aliyuncs.com/jojo/logos/Bzhan_logo.svg"
              alt=""
              class="icon"
              @click="toPage('VUE_APP_BILIBILI')"
            />
            <el-popover popper-class="my-el-pop-qrcode" trigger="hover">
              <template #reference>
                <img
                  src="https://meshmellow.oss-cn-hangzhou.aliyuncs.com/jojo/logos/wechat.svg"
                  alt=""
                  class="icon"
                />
              </template>
              <img
                width="100"
                height="100"
                src="https://meshmellow.oss-cn-hangzhou.aliyuncs.com/img/erweima/wechatQrcode.jpg"
                alt=""
              />
            </el-popover>
            <el-popover popper-class="my-el-pop-qrcode" trigger="hover">
              <template #reference>
                <img
                  src="https://meshmellow.oss-cn-hangzhou.aliyuncs.com/jojo/logos/tiktok.svg"
                  alt=""
                  class="icon"
                />
              </template>
              <img
                width="100"
                height="100"
                src="https://meshmellow.oss-cn-hangzhou.aliyuncs.com/img/erweima/dyerweima.png"
                alt=""
              />
            </el-popover>
            <img
              src="https://meshmellow.oss-cn-hangzhou.aliyuncs.com/jojo/logos/weibo.svg"
              alt=""
              class="icon"
              @click="toPage('VUE_APP_WEIBO')"
            />
          </div>
        </div>
      </van-popup>
      <div class="main">
        <div class="left">
          <img
            :src="require('@/assets/img/meshmellow.png')"
            alt=""
            class="logo cp"
            @click="toPage('VUE_APP_MAINWEBSITE')"
          />
        </div>
        <div class="right">
          <!-- <div class="btn-login">login</div> -->
          <!-- <img
              @click="search"
              :src="require('@/assets/img/search.png')"
              alt=""
              class="btn-search"
          /> -->
          <lang-switcher></lang-switcher>
          <img
            @click="openMenu"
            :src="require('@/assets/img/menu.png')"
            alt=""
            class="btn-menu"
          />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import langSwitcher from "@/components/langSwitcher";
export default {
  name: "mHeader",
  data() {
    return {
      showMenu: false,
      tabs: [
        { name: this.$t('meshSchool'), link: "VUE_APP_LESSON" },
        { name: "GDSC", link: "VUE_APP_GDSC" },
        { name: this.$t('community'), link: "VUE_APP_FORUM" },
      ],
    };
  },
  mounted() {},
  components: {
    langSwitcher,
  },
  methods: {
    openMenu() {
      this.showMenu = true;
    },
    toPage(url) {
      location.href = process.env[url];
    },
    search() {},
  },
};
</script>
