//全局配置公共弹窗
import {Message} from 'element-ui'

function msg(m) {
    return Message({
        center: 'true',
        customClass: 'custom-message',
        message: m
    })
}
export {msg}
