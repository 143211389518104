/* 合法uri*/
function validateURL(textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return urlregex.test(textval);
}

/* 小写字母*/
function validateLowerCase(str) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}

/* 大写字母*/
function validateUpperCase(str) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

/* 大小写字母*/
function validatAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/* 验证邮箱*/
function validateEmail(str) {
  const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
  return reg.test(str);
}

/* 验证4位数字验证码*/
function validateCode(str) {
  const reg = /^\d{4} $/;
  return reg.test(str);
}

/* 验证手机号码 */
function validatePhone(str) {
  const reg = /^1\d{10}$/;
  return reg.test(str);
}

/* 验证用户名 */
function validateUserName(str) {
  return str.length < 20;
}

/* 验证密码 */
function validatePsw(str) {
  return str.length > 5;
}

function checkName(rule, value, callback) {
  if (!value) {
    return callback(new Error("用户名不能为空"));
  } else if (!validateUserName(value)) {
    callback(new Error("用户名格式错误，长度不能超过20位"));
  }
  callback();
}
function checkEmail(rule, value, callback) {
  if (!value) {
    return callback(new Error("邮箱不能为空"));
  } else if (!validateEmail(value)) {
    callback(new Error("邮箱格式错误"));
  }
  callback();
}

function checkPhone(rule, value, callback) {
  if (!value) {
    return callback(new Error("手机号不能为空"));
  } else if (!validatePhone(value)) {
    return callback(new Error("手机号格式错误"));
  }
  callback();
}

function checkPsw(rule, value, callback) {
  if (value === "") {
    callback(new Error("请输入密码"));
  } else if (!validatePsw(value)) {
    callback(new Error("密码大于等于六个字符"));
  } else {
    callback();
  }
}

//捕捉错误
function catchErr(err, $vue) {
  if (err.errors && JSON.stringify(err.errors) !== "{}") {
    for (let i in err.errors) {
      $vue[i + "Err"] = err.errors[i][0];
    }
  } else {
    $vue.$msg(err.message);
  }
}

//字符串插入new字符串
function addNewStr(str, place, newStr) {
  return str.slice(0, place) + newStr + str.slice(place);
}

//字符串某一位转为大写
function toUpperCaseOne(str, place) {
  const UPPER = str.charAt(place).toUpperCase();
  const newStr = str.slice(0, place) + UPPER + str.slice(place + 1);
  return newStr;
}

//字符串某一位转为小写
function toLowerCaseOne(str, place) {
  const UPPER = str.charAt(place).toLowerCase();
  const newStr = str.slice(0, place) + UPPER + str.slice(place + 1);
  return newStr;
}

//字符串去掉某一位字符
function removeOneStr(str, place) {
  return str.slice(0, place) + str.slice(place + 1);
}

//手机端输入pc路由 跳转
function pcToM($vue) {
  let path = $vue.$route.fullPath;
  const isMobile = /(iPhone|iPod|iOS|Android)/i;
  if (isMobile.test(navigator.userAgent)) {
    if (path[1] !== "m") {
      path = toUpperCaseOne(path, 1);
      location.href = addNewStr(path, 1, "m");
    }
  } else {
    if (path[1] === "m") {
      location.href = toLowerCaseOne(removeOneStr(path, 1), 1);
    }
  }
}

export {
  catchErr,
  validatePhone,
  validateEmail,
  validatePsw,
  validateUserName,
};
