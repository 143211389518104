<template>
  <div class="common-title">
    <!-- <img :src="require('@/assets/img/check.png')" alt=""> -->
    <div>{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'commonTitle',
  mounted() {
  },
  props: {
    title: String
  }
}
</script>