import {
    authCheck
} from "../api/api";

export function check() {
    let redirect = decodeURIComponent(location.search.replace("?redirect=", ""))
    if (!redirect) {
        return
    }
    let token = getCookie('token')
    if (token) {
        authCheck().then(res => {
            if (res.data.login) {
                let host = redirect.split("//")[1].split('/')[0]
                let http = redirect.split('//')[0]
                location.href = `${http}//${host}/t?t=${token}&r=${redirect}`
            }
        })
    }
}

//存取cookies
// export function setCookie(name, value) {
//     delCookie(name);
//     let Days = 30;
//     let exp = new Date();
//     exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
//     document.cookie =
//         name +
//         "=" +
//         escape(value) +
//         ";expires=" +
//         exp.toUTCString() +
//         ";domain=.meshmellow.cn";
// }

//读取cookies
export function getCookie(name) {
    let currentDomain = window.location.hostname; // 获取当前域名
    let cookieString = document.cookie; // 获取当前域名下的所有 cookies
    let arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)"); // 用正则匹配指定的 cookie 名称
    if ((arr = cookieString.match(reg))) {
        return unescape(arr[2]); // 返回 cookie 的值
    } else {
        return null; // 如果未找到指定的 cookie，返回 null
    }
}

//删除cookies
export function delCookie(name) {
    let exp = new Date();
    exp.setTime(exp.getTime() - 1);
    let cval = getCookie(name);
    if (cval != null)
        document.cookie = name + "=" + cval + ";expires=" + exp.toUTCString();
}