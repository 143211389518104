// import FastClick from './fastclick'
// window.addEventListener(
//   'load',
//   function() {
//     FastClick.attach(document.body)
//   },
//   false
// )

/* 清除多手指行为事件 */


document.addEventListener('touchstart',function (event) {
    if(event.touches.length>1){
        event.preventDefault();
    }
});
var lastTouchEnd=0;
document.addEventListener('touchend',function (event) {
    var now=(new Date()).getTime();
    if(now-lastTouchEnd<=300){
        event.preventDefault();
    }
    lastTouchEnd=now;
},false);
