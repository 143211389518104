module.exports = {
  phone: "Phone",
  email: "Email",
  lang: "中文",
  usernameLogin: "Username",
  emailLogin: "Email",
  phoneLogin: "Phone",
  P_username: "Please enter username",
  P_psw: "Please enter password",
  P_psw6: "Password",
  cancel: "Cancel",
  Scan_the_code_to_pay_attention_to_the_public_number: "Scan the code to pay attention to the public number",
  In_order_to_check_your_assets: "In order to check your assets more conveniently, it is recommended that you pay attention to 'Meshmellow Study' public account",
  P_phone: "Please enter phone",
  Please_select_the_user_you_want_to_view:'Please select the user you want to view',
  P_code: "Please enter verification code",
  Enter_the_user_information_you_want_to_query: 'Please enter the user id or name',
  And_send: 'And send "Get binding verification code"',
  Press_and_hold_the_image: "Press and hold the image to save or scan to follow",
  Thank_you_for_your_patience: 'Thank you for your patience!',
  Then_you_can_feel_free_to: 'Then you can feel free to operate, start now ~',
  Third_party_authorization: 'Third party authorization',
  Submit_verification_code: 'Submit verification code',
  Please_use_wechat: 'Please use wechat to scan the QR code to follow',
  To_make_it_easier: 'To make it easier to manage your assets, please follow these steps.',
  verification_code: 'verification code',
  The_current_user_name_already_exists: 'The current user name already exists',
  P_email: "Please enter email",
  Successful_merger: 'Successful merger',
  P_cpsw: "Confirm your password",
  search:'Search',
  P_npsw: "New password",
  No_account_yet: 'No account yet',
  P_cnpsw: "Confirm your new password",
  thirdPatyLogin: "Continue with",
  Forgot_your_password: "Forgot your password?",
  It_mainland_phone_number: 'It is not a mainland phone number',
  Skip_this_step: 'Skip this step',
  btn_login: "Log in",
  Go_to_Register: 'Go to Register',
  btn_signUp: "Sign up",
  Existing_account: 'Existing account',
  Go_and_log_in: 'Go and log in',
  signUp_continue: "Continue",
  allowIterm: "Agree to all terms of registration",
  detail: "(detail)",
  username: 'Username',
  getCode: "Get Code!",
  password: 'Password',
  ok_continue: "Continue",
  codeHasBeenSent: "The verification code has been sent, please check",
  noName: "Don't forget to add your username.",
  noPhone: "Don't forget to add your phone.",
  noEmail: "Don't forget to add your email.",
  noPsw: "Don't forget to add your password.",
  noCofirmPsw: "Don't forget to add your password.",
  noCode: "Don't forget to add your code.",
  nameErr: "The password should be shorter than 20 characters.",
  phoneErr: "Hmm...that doesn't look like a phone.",
  Password_more_than_characters: 'Password (more than 6 characters)',
  Confirm_password: 'Confirm password',
  emailErr: "Hmm...that doesn't look like an email address.",
  pswErr: "The password should be longer than 6 characters.",
  confirmPswErr: "The two passwords don't match.",
  followUsOnWechat: "Follow us on wechat!",
  community: "Community",
  meshSchool: "Meshmellow School",
  mainland: "Mobile phone registration is limited to mainland China mobile phone registration, overseas users please use email to register.",
  psc: "Password changed successfully",
  sra: "Successfully registered account",
  ctc: "You need to check the registration terms to continue ~",
  back: 'Back',
  next: 'Next',
  accomplish: 'Accomplish',
  I_have_been_concerned: 'I have been concerned',
  please_follow_these_steps: '* To make it easier to manage your assets, please follow these steps'
};