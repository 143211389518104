import axios from "axios";
import {
  Toast
} from "vant";
import {
  getCookie
} from "../passToken/sso";

const service = axios.create({
  // baseURL: process.env.VUE_APP_API,
  baseURL: process.env.VUE_APP_API,
  // baseURL:
  //   process.env.NODE_ENV === "development"
  //     ? process.env.VUE_APP_APID
  //     : process.env.VUE_APP_API,
  // 'https://devapi.meshmellow.cn',
  timeout: 30000,
});
let loadingInstance;
// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["accept"] = "application/json";
    // config.headers["Content-Type"] = "application/json";
    if (!config.url === "/login/wechat") {
      loadingInstance = Toast.loading({
        message: "加载中",
        forbidClick: true,
        duration: 0,
      });
    }

    // 
    let token = getCookie('token');
    token &&
      (config.headers["Authorization"] =
        "Bearer " + token);
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    if (loadingInstance) {
      loadingInstance.clear();
    }
    return response;
  },
  (error) => {
    if (loadingInstance) {
      loadingInstance.clear();
    }
    if (Number(error.response.status) === 401) {
      localStorage.removeItem("token");
      // 当出现需要登录时候一直弹窗的时候把这个注释掉
      // location.href = `http://account.meshmellow.cn/login?redirect=${location.href}`;
    }

    return Promise.reject(error.response.data);
  }
);
export default service;