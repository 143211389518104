<!--  -->
<template>
  <div class="common-header">
    <div class="left">
      <img
        class="logo cp"
        @click="toIndex"
        src="http://video.meshmellow.cn/cloud/uploads/video/sys/20191210/Nsyb7N5GBxsaBizt7YXT6md5Bt47NRBj.png"
        alt=""
      />
      <div class="user-center">User Center</div>
    </div>
    <div class="right">
      <lang-switcher></lang-switcher>
      <el-popover
        class="my-el-pop-qrcode"
        placement="top-start"
        trigger="hover"
      >
        <div class="">
          <img
            width="140px"
            height="140px"
            src="https://meshmellow.oss-cn-hangzhou.aliyuncs.com/jojo/competition/MM%20School.jpg"
            alt=""
          />
          <div style="width: 140px; text-align: center">扫码关注微信公众号</div>
        </div>
        <div class="qrcode" slot="reference">
          <img src="http://video.meshmellow.cn/img/header/erweima.svg" />
        </div>
      </el-popover>
      <!-- <div class="lan"></div> -->
    </div>
  </div>
</template>

<script>
import langSwitcher from "@/components/langSwitcher";

export default {
  data() {
    return {};
  },
  components: {
    langSwitcher,
  },
  // 方法集合
  methods: {
    toIndex() {
      window.open(process.env.VUE_APP_MAINWEBSITE, "_self");
    },
  },
};
</script>
<style lang="scss" >
.common-header {
  width: 100%;
  height: 67px;
  background-color: #000;
  border-bottom: 5px solid #FAB400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    margin-left: 39px;
    align-items: center;
    .logo {
      width: 180px;
    }
    .user-center {
      width: 110px;
      height: 24px;
      font-weight: bolder;
      font-size: 16px;
      color: black;
      line-height: 24px;
      background-color: #fff;
      text-align: center;
      margin-left: 10px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    margin-right: 28px;
    .qrcode {
      width: 26px;
      height: 26px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .lan {
      width: 100px;
      height: 25px;
      border-radius: 4px;
      background-color: #444;
      color: white;
      visibility: hidden;
      font-weight: bold;
      margin-left: 40px;
    }
  }
}
</style>
