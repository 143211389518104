import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/mLogin',
    redirect: '/login',
  },
  {
    path: '/login',
    name: '登 录',
    component: () => import('@/views/login'),
  },
  {
    path: '/register',
    name: '注 册',
    component: () => import('@/views/register'),
  },
  {
    path: '/resetPsw',
    name: '修 改 密 码',
    component: () => import('@/views/resetPsw'),
  },
  {
    path: '/mRegister',
    redirect: '/register',
  },
  {
    path: '/mResetPsw',
    redirect: '/resetPsw',
  },
  {
    path: '/test2',
    component: () => import('@/views/test2'),
  },
  {
    path: '*',
    name: 'Not Found',
    component: () => import('@/views/NotFound'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
