<template>
  <div>
    <div
      class="botton"
      @click="to('https://beian.miit.gov.cn/#/Integrated/index')"
    >
      <span> 沪ICP备15028626号-4</span>
    </div>
    <div style="display:flex;justify-content: center;margin-top:10px;">
      <img
        src="https://beian.mps.gov.cn/img/logo01.dd7ff50e.png"
        style="margin-right:5px;"
        width="20"
      />
      <a
        href="https://beian.mps.gov.cn/#/query/webSearch?code=沪公网安备31011002006809号"
        rel="noreferrer"
        target="_blank"
        >沪公网安备31011002006809号
      </a>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    to(e) {
      location.href = e;
    },
  },
};
</script>
<style lang="scss" scoped>
.botton {
  display: flex;

  align-items: center;
  justify-content: center;
  /* margin: 0 auto; */
  span {
    color: #ccc;
    cursor: pointer;
    &:hover {
      // color: rgb(34, 34, 34);
    }
  }
}
</style>
