//需要存储token的网站;
let websiteList = [
  // 'http://forum.vertexmonkey.net/token',
  // "http://vertexmonkey.net/t",
  // 'http://community.meshmellow.cn/token',
  // 'http://m.cloud.meshmellow.cn/token',
  // 'http://cloud.meshmellow.cn/token',
  // 'http://dev.cloud.meshmellow.cn/token',
  // "http://127.0.0.1:8080/token",
  // "http://mesh2.test/index",
  // 'http://user.meshmellow.cn/token',
];

//token发射器初始化 --需要放在mounted里
function initTokenSender() {
  if (websiteList.length) {
    let app = document.querySelector("#app");
    websiteList.forEach((v, i) => {
      let myIframe = document.createElement("iframe");
      myIframe.src = v;
      myIframe.setAttribute("class", "tokenSender");
      myIframe.style.display = "none";
      app.appendChild(myIframe);
    });
  }
}

//store token --放在登入成功的操作后
function tokenSend(token) {
  let iframeWindows = document.querySelectorAll(".tokenSender");
  if (iframeWindows.length) {
    iframeWindows.forEach((v, i) => {
      v.contentWindow.postMessage(token, v.src);
    });
  }
}
export { initTokenSender, tokenSend };
