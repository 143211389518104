<template>
  <div id="app">
    <div class="notMobile">
      <common-header></common-header>
      <div class="login-container">
        <div class="login-window">
          <div class="btn-back" @click="goBack" v-if="$route.path !== '/login'">
            <i class="el-icon-arrow-left" style="font-weight: bold"></i>
            <span style="padding-right: 3px">{{ $t("back") }}</span>
          </div>
          <!-- <img
            :src="require('@/assets/img/WechatIMG6.jpg')"
            alt=""
            class="banner-img"
          /> -->
          <div class="router-content">
            <common-title :title="langTitle($route.name)" />
            <router-view :key="$route.fullPath" />
          </div>
        </div>
      </div>
      <mFootter />
    </div>
    <div class="mobile">
      <m-header></m-header>
      <mBreadCrumb :title="langTitle($route.name)" />
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>
<script>
import mFootter from "@/components/mFootter";
import commonTitle from "@/components/commonTitle";
import commonHeader from "@/components/commonHeader";
import mHeader from "@/components/mHeader";
import mBreadCrumb from "@/components/mBreadCrumb";
import { initTokenSender, tokenSend } from "@/passToken";

export default {
  name: "App",
  components: {
    commonTitle,
    commonHeader,
    mHeader,
    mBreadCrumb,
    mFootter,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  created() {
    const isMobile = /(iPhone|iPod|iOS|Android)/i;
    if (!isMobile.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  mounted() {
    initTokenSender();
  },
  methods: {
    goBack() {
      console.log(12345);
      location.href = `${location.origin}/login`;
    },
    langTitle(name) {
      if (this.$i18n.locale === "en") {
        if (name === "修 改 密 码") {
          return "Reset Password";
        }
        if (name === "登 录") {
          return "Login";
        }
        if (name === "注 册") {
          return "Sign up";
        }
      } else {
        return name;
      }
    },
  },
};
</script>
<style lang="scss">
.mobile {
  color: #fff;
}
html {
  background: #111;
}
@media screen and (max-width: 600px) {
  .notMobile {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .mobile {
    display: none;
  }
}
</style>
