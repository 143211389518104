<template>
  <div @click="switchLang" class="common-lang-swicher">{{ $t("lang") }}</div>
</template>
<script>
export default {
  name: "langSwitcher",
  data() {
    return {};
  },
  methods: {
    switchLang() {
      if (this.$i18n.locale === "en") {
        localStorage.lang = "zh";
        location.reload();
      } else {
        localStorage.lang = "en";
        location.reload();
      }
    },
  },
};
</script>