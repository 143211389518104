<template>
  <div class="mBreadCrumb">
      <div class="redLine"></div>
      <div class="routeName">{{title}}</div>
  </div>
</template>

<script>

export default {
    name:'mBreadcrumb',
    props:{
      title:String
    }
}
</script>
