import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import store from './store'
import "../src/sass/element-variables.scss";
import {
  Popup,
  Icon
} from "vant";
import "vant/lib/icon/local.css";
import delCookie from '@/js/pages/login';
import {
  msg
} from "@/js/message";
import VueI18n from "vue-i18n";
import {
  Form,
  RadioGroup,
  Radio,
  RadioButton,
  FormItem,
  Checkbox,
  Button,
  Popover,
  Input,
  Image,
  Pagination
} from "element-ui";

// custom
import "@/assets/css/index.css";
import "@/assets/js/common.js";
import "@/sass/index.scss";

Vue.prototype.$msg = msg;
Vue.config.productionTip = false;

Vue.use(Popup)
  .use(Icon)
  .use(Popover)
  .use(Button)
  .use(Input)
  .use(Form)
  .use(FormItem)
  .use(Checkbox)
  .use(Image)
  .use(Pagination)
  .use(RadioGroup)
  .use(Radio)
  .use(VueI18n)
  .use(RadioButton);

const i18n = new VueI18n({
  locale: "zh", // 语言标识
  messages: {
    zh: require("./lang/zh"),
    en: require("./lang/en"),
  },
});
const setLang = (lang) => {
  i18n.locale = lang;
  localStorage.lang = lang;
};

// function clearAllCookies() {
//   var cookies = document.cookie.split("; ");
//   for (var i = 0; i < cookies.length; i++) {
//     var cookie = cookies[i];
//     var eqPos = cookie.indexOf("=");
//     var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
//     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
//   }
// }
// clearAllCookies();
// console.log('====================================');
// console.log(12334567);
// console.log('====================================');
// document.cookie.token = ''
const LangInit = () => {
  if (localStorage.lang && localStorage.lang !== "undefined") {
    setLang(localStorage.lang);
  } else {
    var UAlang = navigator.language || navigator.userLanguage; //常规浏览器语言和IE浏览器
    UAlang = UAlang.substr(0, 2); //截取lang前2位字符
    if (UAlang === "zh") {
      setLang("zh");
    } else {
      setLang("en");
    }
  }
};
LangInit()
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");